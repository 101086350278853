<template>
  <div
    class="login login-signin-on login-3 d-flex flex-row-fluid"
    id="kt_login"
  >
    <div
      v-if="false"
      class="login login-signin-on login-3 d-flex flex-row-fluid"
      id="kt_login"
    >
      <div
        class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
        style="background-image: url('/assets/media/bg/bg-3.jpg')"
      >
        <div
          class="login-form text-center p-7 position-relative overflow-hidden"
          style="min-width: 400px"
        >
          <h3 class="text-primary">
            {{ $t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION") }}
          </h3>
          <p class="mt-6 font-weight-bolder">
            {{ $t("ADVAUTHMETHOD.PARTECIPAPROCESSO") }}
          </p>

          <div class="d-flex flex-center my-0">
            <a href="/">
              <img
                :src="
                  getOrganization.data
                    ? getOrganization.data.logo1Uri
                    : '/assets/media/bipart/logo-bipart-brand.svg'
                "
                style="max-height: 160px; max-width: 260px"
                alt="Logo"
              />
            </a>
          </div>
          <div class="login-signup">
            <div class="mb-2">
              <div class="mt-10 w-100 text-center">
                <div
                  class="spinner spinner-primary spinner-lg spinner-center"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      style="background-image: url('/assets/media/bg/bg-3.jpg')"
    >
      <div
        class="login-form text-center p-7 position-relative overflow-hidden"
        style="min-width: 400px"
      >
        <h3 class="text-primary">
          {{ $t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION") }}
        </h3>
        <p class="mt-6 font-weight-bolder">
          {{ $t("ADVAUTHMETHOD.PARTECIPAPROCESSO") }}
        </p>

        <div class="d-flex flex-column flex-center my-5">
          <div
            v-if="this.requireConditions"
            class="h5 py-5 border-bottom border-bottom-2 dashed text-primary"
          >
            {{ getOrganization.data.name }}
          </div>
          <a href="/">
            <img
              :src="
                getOrganization.data
                  ? getOrganization.data.logo1Uri
                  : '/assets/media/bipart/logo-bipart-brand.svg'
              "
              style="max-height: 160px; max-width: 260px"
              alt="Logo"
            />
          </a>
        </div>

        <!--begin::Login Sign in form-->
        <div v-if="!this.requireConditions" class="login-signin">
          <div class="h5 py-5 border-top border-top-2 dashed text-primary">
            {{ getOrganization.data.name }}
          </div>
          <form class="form text-left" @submit.stop.prevent="onSubmit">
            <div
              role="alert"
              v-bind:class="{ show: errors.length }"
              class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
            <div class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="text"
                placeholder="username"
                name="username"
                id="username"
                v-model="$v.form.username.$model"
                :state="validateState('username')"
                aria-describedby="username-live-feedback"
                autocomplete="off"
                :class="{
                  invalid: $v.form.username.$dirty && $v.form.username.$invalid,
                }"
              />
              <template
                v-if="$v.form.username.$dirty && $v.form.username.$invalid"
              >
                <span v-if="!$v.form.username.required" class="error pl-5">{{
                  $t("LOGIN.USERNAMENONESISTE")
                }}</span>
              </template>
            </div>
            <div class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="password"
                placeholder="Password"
                id="password"
                name="password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                :class="{
                  invalid: $v.form.password.$dirty && $v.form.password.$invalid,
                }"
              />
              <template
                v-if="$v.form.password.$dirty && $v.form.password.$invalid"
              >
                <span v-if="!$v.form.password.required" class="error pl-5">{{
                  $t("LOGIN.PASSWORDERRATA")
                }}</span>
              </template>
            </div>
            <div v-if="false" class="d-flex justify-content-between mt-n5">
              <label class="font-size-h6 pt-5">{{
                $t("LOGIN.NONRICORDIPASS")
              }}</label>
              <a
                href="/assets/email/request-password.html"
                class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                >{{ $t("LOGIN.CAMBIAPASS") }}</a
              >
            </div>

            <div class="text-center mt-3">
              <button
                id="kt_login_signin_submit"
                class="btn btn-primary btn-block font-weight-bolder px-9 py-4 text-uppercase"
                ref="kt_login_signin_submit"
              >
                {{ $t("LOGIN.ACCEDI") }}
              </button>
            </div>
          </form>
          <div v-if="isActivePortal" class="mt-5">
            <span class="font-weight-bold font-size-h6 text-uppercase">
              <router-link to="/portal">{{
                $t("LOGIN.BACKPORTAL")
              }}</router-link></span
            >
          </div>
        </div>
        <!--end::Login Sign in form-->

        <!--begin:: Require condition form -->
        <div class="text-center" v-else>
          <h2 class="font-wight-bolder">{{ $t("LOGIN.UTENTEAUTENTICATO") }}</h2>
          <div style="max-width: 400px" class="mt-5 mx-auto">
            <p class="lead" v-html="$t('LOGIN.MEXLOGIN')"></p>
          </div>

          <form class="form" @submit.stop.prevent="onSubmitconditions">
            <div
              role="alert"
              v-bind:class="{ show: errors.length }"
              class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>

            <p v-if="!emailExists" class="font-weight-bolder">
              {{ $t("LOGIN.INSERISCIMAIL") }}
            </p>

            <div v-if="!emailExists" class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="text"
                placeholder="email"
                name="email"
                id="email"
                v-model="email"
                aria-describedby="email-live-feedback"
                autocomplete="off"
              />
            </div>
            <p v-if="!emailExists" class="text-muted font-weight-bolder">
              {{ $t("LOGIN.NOTIFICHE") }}
            </p>

            <p class="text-left" v-html="$t('LOGIN.TERMINICONDIZIONI')"></p>

            <div
              class="form-group d-flex flex-wrap justify-content-between align-items-center"
            >
              <label class="checkbox m-0">
                <input
                  type="checkbox"
                  name="term_and_conditions"
                  v-model="term_and_conditions"
                  class="checkbox-input" />{{ $t("LOGIN.ACCETTO1") }}
                <router-link to="/terms" target="_blank">{{
                  $t("LOGIN.TERMINI")
                }}</router-link>
                <span></span
              ></label>
            </div>
            <div
              class="form-group d-flex flex-wrap justify-content-between align-items-center"
            >
              <label class="checkbox m-0">
                <input
                  type="checkbox"
                  name="privacy"
                  v-model="privacy"
                  class="checkbox-input" />{{ $t("LOGIN.ACCETTO2") }}
                <router-link to="/privacy" target="_blank">{{
                  $t("LOGIN.PRIVACY")
                }}</router-link>
                <span></span
              ></label>
            </div>

            <button
              :disabled="isDisabled"
              id="kt_conditions_accepted_submit"
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              ref="kt_login_conditions_accepted_submit"
            >
              {{ $t("LOGIN.PROSEGUI") }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <password-recovery
      ref="passwordRecovery"
      @switchToOTPVerify="manageMobileVerify"
    >
    </password-recovery>
    <password-mobile-verify
      ref="passwordMobileVerify"
      @switchToPasswordChange="managePasswordChange"
    ></password-mobile-verify>
    <password-change ref="passwordChange"></password-change>
  </div>
  <!--end::Login-->
</template>

<style lang="scss">
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.error {
  color: #ff0000;
}
.invalid {
  border-color: #ff0000;
}

.login {
  .checkbox span {
    outline: 1px solid hsla(0, 0%, 77%, 0.7);
  }
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import {
  LOGIN,
  SET_HEADER_TOKEN,
  REFRESH_TOKEN,
} from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import store from "@/core/services/store";

import PasswordRecovery from "@/view/pages/auth/partials/PasswordRecovery";
import PasswordMobileVerify from "@/view/pages/auth/partials/PasswordMobileVerify";
import PasswordChange from "@/view/pages/auth/partials/PasswordChange";

export default {
  name: "login",
  mixins: [validationMixin],
  components: {
    PasswordMobileVerify,
    PasswordRecovery,
    PasswordChange,
  },
  data() {
    return {
      // errors: [],
      requireConditions: false,
      term_and_conditions: false,
      privacy: false,
      emailExists: false,
      email: "",

      // Remove this dummy login info
      form: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["getOrganization", "currentUser"]),
    isDisabled: function () {
      return !this.privacy || !this.term_and_conditions;
    },
    isActivePortal() {
      return (
        this.getOrganization?.data &&
        this.getOrganization?.data?.umbrellaWorkspaceActive
      );
    },
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onSubmitconditions() {
      if (this.email && !this.validateEmail(this.email)) {
        Swal.fire(this.$t("LOGIN.TITLE5"), this.$t("LOGIN.MEX5"), "warning");
        return false;
      }

      // Aggiorno il profilo dell'utente con le accettazioni
      let acceptData = {
        id: this.currentUser.id,
        termsConditionsAccepted: true,
        privacyPolicyAccepted: true,
        email: this.email,
      };
      new Promise((resolve, reject) => {
        ApiService.put(this.$apiResource + "/users/mandatory-flags", acceptData)
          .then((res) => {
            // Aggiorno il token
            store.dispatch(REFRESH_TOKEN, res.headers.token);
            Swal.fire(this.$t("LOGIN.TITLE1"), this.$t("LOGIN.MEX1"), "info");

            setTimeout(() => {
              // Invio l'utente alla home
              this.$router.push({ name: "intro" });
            }, 500);

            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let credentials = {
        username: username,
        password: password,
        // selectedOrganizationId: 1,
        selectedOrganizationDomain: this.$currentDomain,
        fetchEnabledOnly: true,
      };

      let loginResult = await this.$store.dispatch(LOGIN, credentials);
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      if (loginResult.headers.token == "BadCredentialsException") {
        Swal.fire(this.$t("LOGIN.TITLE2"), this.$t("LOGIN.MEX2"), "info");
      } else {
        /* */
        const userId = jwt_decode(loginResult.headers.token).id;

        await new Promise((resolve, reject) => {
          // setTimeout( () => {
          store.dispatch(SET_HEADER_TOKEN);
          var endpoint = this.generateUrl(
            "/users/mandatory-flags/" + userId,
            false,
            false
          );
          //generateUrl is done
          console.log("mandatory-flags:", this.$apiResource + endpoint);
          ApiService.get(this.$apiResource + endpoint)
            .then((res) => {
              if (
                (res.data.privacyPolicyAccepted &&
                  res.data.termsConditionsAccepted) ||
                !this.getOrganization.data.twoLevelLogin
              ) {
                Swal.fire(
                  this.$t("LOGIN.TITLE3"),
                  this.$t("LOGIN.MEX3"),
                  "success"
                );
                // this.$router.push({name: "intro"});
                this.$router.push({ name: "intro" });
              } else {
                this.emailExists = res.data.email ? true : false;
                this.email = res.data.email;
                this.requireConditions = true;
              }
            })
            .catch((e) => {
              console.log("ERRORE");
              console.log(e.message);
              Swal.fire(
                this.$t("LOGIN.TITLE4"),
                this.$t("LOGIN.MEX4"),
                "error"
              );
              reject(0);
            });

          // }, 500);
        });
        /* */
      }
    },

    manageMobileVerify() {
      this.$refs["passwordMobileVerify"].$refs[
        "modalPasswordMobileVerify"
      ].show();
    },
    managePasswordChange() {
      this.$refs["passwordChange"].$refs["modalPasswordChange"].show();
    },
  },
};
</script>
